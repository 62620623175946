import React, { useState } from 'react';
import styled from 'styled-components';
import { Hero, SEO, StaticCaseStudies, RenderWhenReady, LikeWhatYouSee } from 'components';
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import PrevArrow from 'images/shared/PrevArrow';

const MarketingPage = () => {
  const data = useStaticQuery(
    graphql`
      query {
        thumbnailImage: file(relativePath: { eq: "service-pages/digital-video-thumbnail.png" }) {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: TRACED_SVG, formats: AUTO, quality: 90)
          }
        }
        ifa: file(
          relativePath: { eq: "service-pages/healthcare-digital-and-social-campaigns/ifa.png" }
        ) {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: TRACED_SVG, formats: AUTO, quality: 90)
          }
        }
        pfizerWorldAntimicrobial: file(
          relativePath: {
            eq: "service-pages/healthcare-digital-and-social-campaigns/pfizer-world-antimicrobial.png"
          }
        ) {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: TRACED_SVG, formats: AUTO, quality: 90)
          }
        }
      }
    `
  );

  const caseStudies = [
    {
      slug: '/our-work/ifa-you-are-the-heart-of-your-farm/',
      image: data.ifa,
      title: 'Targeted Public Awareness Campaign',
      company: "Irish Farmer's Association"
    },
    {
      slug: '/our-work/pfizer-healthcare-ireland-world-antimicrobial-awareness-week-2021/',
      image: data.pfizerWorldAntimicrobial,
      title: 'Content Strategy, Social Media Campaign Strategy, Digital Campaign',
      company: 'Pfizer Healthcare Ireland'
    }
  ];

  return (
    <>
      <SEO
        pageSpecificTitle="Digital & Social"
        pageSpecificDescription="Comprehensive Digital & Social Marketing Campaigns"
      />
      <Hero>
        <HeroContent>
          <Directions>
            <StyledLink to="/#services">
              <BackButton>
                <StyledPrevArrow />
              </BackButton>
            </StyledLink>
            <StyledLink to="/services/healthcare-brand-and-design">
              <NextButton>
                <StyledNextArrow />
              </NextButton>
            </StyledLink>
          </Directions>
          <h1>
            Digital
            <br />& Social
          </h1>
          <Text>
            <h2>Comprehensive Social & Digital Healthcare Marketing Campaigns</h2>
            <p>
              Our social and digital marketing strategies are focussed on achieving business goals
              for our clients. Whether its building brand awareness, or gathering a community around
              a particular topic, we will define your target audience and build a communication
              strategy that gathers momentum. Always within the IPHA code of practice.
            </p>
          </Text>
        </HeroContent>
      </Hero>
      <Section style={{ padding: 0 }}>
        <Video>
          <GatsbyImage image={getImage(data.thumbnailImage)} alt="Video thumbnail" />
          <video
            muted
            autoPlay
            loop
            playsInline
            id="Digital & Social"
            preload="auto"
            controls={false}>
            <source src="/digital-video.mp4" type="video/mp4" />
          </video>
        </Video>
      </Section>
      <Section>
        <Article>
          <ArticleTitle>Our Approach</ArticleTitle>
          <Column>
            <p>
              We understand the need to connect with healthcare audiences using relevant targeted
              content on all of the digital channels and technologies available to consumers today.
            </p>
            <p>
              We work extensively with all the big social platforms, like LinkedIn, Instagram and
              Facebook (Meta), and digital channels such as Google Display and Search.
            </p>
          </Column>
          <Column>
            <p>
              Beginning with forming a clear understanding of the target audience and the need for
              cut-through content, we work towards identifying key insights that ultimately drive
              the campaign messaging and creative approach. With a big focus on delivering results
              for our clients, we will manage day-to-day campaign optimisation, and present a full
              Analytics & Insights report for each client both during and at post campaign stages.
            </p>
          </Column>
        </Article>
      </Section>
      <Section>
        <Article>
          <ArticleTitle>Agency X Digital Marketing Campaign Services Include</ArticleTitle>
          <Column>
            <ul>
              <li>Data Insights</li>
              <li>Social Media Marketing</li>
              <li>Google Display & Search</li>
              <li>Storyboarding & Script writing for Social Videos</li>
            </ul>
          </Column>
          <Column>
            <ul>
              <li>Customer Segmentation & Insights</li>
              <li>Copywriting</li>
              <li>Media Buying (All Social Channels)</li>
              <li>Analytics Reporting</li>
            </ul>
          </Column>
        </Article>
      </Section>
      <Section>
        <h2>Case Studies</h2>
        <p>
          Please find some of our recently produced events in our case study links below. All our
          projects are customised to the specific requirements of our clients and we will be
          delighted to share our experiences of best practice for your business event upon request.
        </p>
        <StaticCaseStudies caseStudies={caseStudies} />
      </Section>
      <LikeWhatYouSee margin />
    </>
  );
};

export const Head = () => (
  <link rel="preload" as="video" type="video/mp4" href="/digital-video.mp4" />
);

const Directions = styled.div`
  align-items: center;
  display: flex;
  grid-column: span 2;
  justify-content: space-between;
  width: 100%;
`;

const BackButton = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  width: 3rem;
  height: 3rem;
  border-radius: 0.125rem;
  transition: 150ms ease-in-out;
  &:hover {
    background-color: #000;
  }
  &:not(:hover) {
    background-color: var(--ax-gold);
  }
  &:active {
    background-color: #c1beac;
  }
`;

const NextButton = styled(BackButton)`
  svg {
    transform: rotate(180deg);
  }
`;

const StyledPrevArrow = styled(PrevArrow)`
  width: 1.688rem;
`;

const StyledNextArrow = styled(PrevArrow)`
  width: 1.688rem;
`;

const StyledLink = styled(AnchorLink)`
  display: block;
  grid-column: 1;
  margin-top: -0.75em;
  margin-bottom: 1rem;
  @media (min-width: 48em) {
    grid-column: 1/3;
  }
`;

const Video = styled.div`
  width: 100%;
  height: auto;
  margin: 0 auto;
  position: relative;
  video {
    width: 100%;
    height: auto;
    position: relative;
    z-index: 1;
    top: 0;
    left: 0;
    object-fit: cover;
    object-position: center;
    z-index: 1;
  }
  .gatsby-image-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    z-index: 0;
  }
`;

const ArticleTitle = styled.h3`
  grid-column: span 1;
  font-size: 1.5rem;
  font-weight: 800;
  text-align: left;
  @media (min-width: 48em) {
    grid-column: span 2;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  grid-column: span 2;
  h3 {
    font-size: 1.5rem;
    font-weight: 800;
    margin-bottom: 2rem;
  }
  ul {
    list-style: none;
    li {
      font-size: 1rem;
      line-height: 2em;
      list-style-type: none;
      text-decoration: underline;
      -webkit-text-decoration-color: var(--ax-gold); /* Safari */
      text-decoration-color: var(--ax-gold);
      width: auto;
    }
  }
  p {
    line-height: 1.3em;
    font-size: 1.25rem;
    margin-bottom: 1.5rem;
    &:last-child {
      margin-bottom: 0;
    }
    strong {
      padding-left: 2rem;
      position: relative;
      display: inline-block;
      font-size: 0.75rem;
      line-height: 1.5em;
      &:before {
        content: '★';
        font-size: 1.25rem;
        color: var(--ax-gold);
        position: absolute;
        left: 0;
      }
    }
  }
  .gatsby-image-wrapper {
    width: 100%;
    height: 300px;
  }
  @media (min-width: 48rem) {
    ul {
      li {
        font-size: 1.5rem;
      }
    }
  }
  @media (min-width: 62.5rem) {
    grid-column: span 1;
    .gatsby-image-wrapper {
      height: 100%;
    }
  }
`;

const Article = styled.article`
  grid-column: 2/3;
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 1.5rem;
  grid-column-gap: 3rem;
  margin: 2rem auto;
  margin-bottom: 0;
  width: 100%;
  @media (min-width: 62.5rem) {
    grid-row-gap: 2.5rem;
    grid-column-gap: 2.5rem;
    grid-template-columns: 1fr 1fr;
  }
`;

const Section = styled.section`
  width: 100%;
  height: auto;
  max-width: 108.75rem;
  padding: 2rem 0;
  margin: 0 auto;
  > div {
    grid-column: 2/3;
  }
  > h2 {
    color: var(--ax-gold);
    font-size: 1.75rem;
    grid-column: 2/3;
    margin-bottom: 3rem;
    text-align: center;
  }
  > p {
    font-size: 1.25rem;
    line-height: 1.3em;
    text-align: center;
    grid-column: 2/3;
    margin-bottom: 3rem;
  }

  @media (min-width: 48rem) {
    padding: 3rem;
    > h2 {
      font-size: 2rem;
      text-transform: uppercase;
    }
    > p {
      margin-bottom: 5rem;
    }
  }
`;

const Text = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 1rem;
  svg {
    height: 1.5rem;
    align-self: flex-start;
    margin-bottom: 2rem;
  }
  @media (min-width: 48em) {
    svg {
      align-self: flex-start;
    }
  }
  @media (min-width: 67.5em) {
    svg {
      height: 2.5rem;
    }
  }
`;

const HeroContent = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  h1 {
    font-size: 2.5rem;
    margin-top: 0.125rem;
    margin-bottom: 2rem;
    grid-column: span 2;
  }
  h2 {
    font-size: 1.25rem;
    line-height: 1.3em;
    margin-bottom: 1em;
    font-weight: 300;
    grid-column: span 2;
  }
  @media screen and (min-width: 48rem) {
    h1 {
      font-size: 2.75rem;
      grid-column: span 1;
    }
    h2 {
      font-size: 2rem;
      grid-column: span 1;
    }
    grid-template-columns: 1fr 1fr;
    grid-gap: 1.5rem;
    max-width: 1180px;
    margin: 3rem auto 3rem;
    align-items: start;
  }
  @media screen and (min-width: 67.5rem) {
    h1 {
      font-size: 4rem;
    }
    grid-template-columns: 1fr 1fr;
    grid-gap: 3rem;
  }
  @media screen and (min-width: 82.5rem) {
    h1 {
      font-size: 5rem;
    }
  }
`;

export default MarketingPage;
